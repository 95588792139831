@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600&display=swap');

::-webkit-scrollbar {display:none;}

body {
  margin: 0;
font-family: 'Raleway', sans-serif;  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



#preloader {
  background: #000 url(./Assets/loading.gif) no-repeat center;
  background-size: 30%;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;
}


@media only screen and (min-width: 600px) {
  #preloader {
  background: #000 url(./Assets/loading.gif) no-repeat center;
  background-size: 10%;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;
}}